import axios from "axios";
import { DriverProfile } from "../types/driverProfile";
import { PersonalStats } from "../types/personalStats";
import { Leaderboard } from "../types/leaderboard";
import { getIntervalByName } from "../utils/formatDate.ts";

const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME || "https://lost-angel.ru"

type Period = "DAY" | "WEEK" | "MONTH"

export async function getDriverProfile(driverId: string): Promise<DriverProfile> {
    const result = await axios.get(`${DOMAIN_NAME}/api/driver/${driverId}`)
    return result.data
}

export async function getActualDriverProfile(driverId: string): Promise<DriverProfile> {
    const data = {
        driverId
    }
    const result = await axios.post(`${DOMAIN_NAME}/api/driver`, data)
    return result.data
}

export async function getPersonalStats(driverId: string, period: Period): Promise<PersonalStats> {
    const { from, to } = getIntervalByName(period)
    const result = await axios.get(`${DOMAIN_NAME}/api/driver-summary?driverId=${driverId}&from=${from}&to=${to}`)
    return result.data
}

export async function getLeaderboard(period: Period): Promise<Leaderboard> {
    const { from, to } = getIntervalByName(period)

    const result = await axios.get(`${DOMAIN_NAME}/api/leaderboards?from=${from}&to=${to}`)
    return result.data

}

export async function validateDriver(driverId: string, telegramId: string): Promise<boolean> {

    try {
        const response = await axios.post(`${DOMAIN_NAME}/api/validate-driver`, {
            driverId,
            telegramId
        });

        return true;
    } catch (error) {
        console.error("Ошибка при валидации пользователя:", error);
        return false; // Возвращаем false в случае ошибки
    }
}
