import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DriverContext = createContext({ driverId: '', telegramId: '' });

export const useDriver = () => useContext(DriverContext);

export const DriverProvider = ({ children }) => {
    const location = useLocation();

    const [driver, setDriver] = useState(() => {

        const queryParams = new URLSearchParams(location.search);
        let driverId = queryParams.get('driverId');

        if (driverId) {

            localStorage.setItem('driverId', driverId);
        }
        else {
            driverId = localStorage.getItem('driverId') || '';
        }



        return driverId || ""
    });

    const [telegramUser, setTelegramUser] = useState(() => {

        const queryParams = new URLSearchParams(location.search);
        let telegramId = queryParams.get('telegramId')

        if (telegramId) {
            localStorage.setItem('telegramId', telegramId);
        }
        else {
            telegramId = localStorage.getItem('telegramId') || ''
        }

        return telegramId || ""
    })

    useEffect(() => {

        if (!driver) {
            const queryParams = new URLSearchParams(location.search);
            const driverFromQuery = queryParams.get('driverId');

            if (driverFromQuery) {
                setDriver(driverFromQuery);
                localStorage.setItem('driverId', driverFromQuery);
            }
        }
    }, [location.search, driver]);

    useEffect(() => {

        if (!telegramUser) {
            const queryParams = new URLSearchParams(location.search);
            const telegramUserFromQuery = queryParams.get('telegramId');

            if (telegramUserFromQuery) {
                setTelegramUser(telegramUserFromQuery);
                localStorage.setItem('telegramId', telegramUserFromQuery);
            }
        }
    }, [location.search, telegramUser]);

    return (
        <DriverContext.Provider value={{ driverId: driver, telegramId: telegramUser }}>
            {children}
        </DriverContext.Provider>
    );
};
