import { useState, useEffect } from "react";
import { getProductsByFolder } from "../services/shop.ts";
import { Product } from "../types/product.ts";

function useProducts(filter: string) {
    const [products, setProducts] = useState<Product[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    async function fetchProducts() {
        setIsLoading(true); // Устанавливаем состояние загрузки
        const products = await getProductsByFolder(filter);
        setProducts(products);
        setIsLoading(false); // Загрузка завершена
    }

    useEffect(() => {
        fetchProducts();
    }, [filter]);

    return { products, isLoading }; // Возвращаем продукты и статус загрузки
}

export default useProducts;
