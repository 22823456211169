import React, { useEffect, useState } from "react";
import ProfileCard from "./ProfileCard.tsx";
import BalanceContainer from "./BalanceContainer.tsx";
import PersonalStats from "./PersonalStats.tsx";
import ShopPromo from "./ShopPromo.tsx";
import Leaderboards from "./Leaderboards.tsx";
import NavBar, { State } from "../navigation/NavBar.tsx";
import { useDriver } from "../../providers/DriverProvider.tsx";
import useDriverProfile from "../../hooks/useDriverProfile.tsx";
import { validateDriver } from "../../services/driver.ts";
import { CiWarning } from "react-icons/ci";

function Profile() {

    const containerStyle: React.CSSProperties = {
        backgroundColor: 'black',
        height: `calc(100vh - ${40}px)`,
        padding: `${40}px 40px`,
        overflowY: 'auto',
    }

    const { driverId, telegramId } = useDriver()
    const driverProfile = useDriverProfile(driverId)
    const [isValidUser, setIsValidUser] = useState<boolean | null>(null);


    useEffect(() => {
        const checkUser = async () => {
            const isValid = await validateDriver(driverId, telegramId);
            setIsValidUser(isValid);
        };

        checkUser();
    }, [driverId, telegramId]);

    if (isValidUser === null) {
        return <div style={containerStyle}>
        </div>
            ;
    }

    if (!driverProfile) {
        return <div style={containerStyle}>
        </div>
            ;
    }

    if (!isValidUser) {
        return <div style={{
            backgroundColor: 'black',
            height: `calc(100vh - ${40}px)`,
            padding: `${40}px 40px`,
            color: 'white',
            display: 'flex', // Activates flexbox
            justifyContent: 'center', // Centers horizontally
            alignItems: 'center', // Centers vertically
            flexDirection: 'column', // Aligns content in a column layout
            textAlign: 'center', // Centers text in the column

        }}>

            <CiWarning size={150} color="DD5C00"></CiWarning>
            <p>Вы не имеете доступ к этому профилю</p>
        </div>;
    }

    return (
        <div style={containerStyle}>
            <ProfileCard driver={driverProfile}></ProfileCard>
            <BalanceContainer driver={driverProfile}></BalanceContainer>
            <PersonalStats driverId={driverId}></PersonalStats>
            <ShopPromo></ShopPromo>
            <Leaderboards></Leaderboards>
            <NavBar state={"HOME"} />
        </div>
    )
}

export default Profile;
