import React, { useState } from "react";
import { State } from "./components/navigation/NavBar.tsx";
import NavBar from "./components/navigation/NavBar.tsx";
import { NAVBAR_HEIGHT } from "./components/navigation/NavBar.tsx";
import Profile from "./components/profile/Profile.tsx";
import Leaderboards from "./components/leaderboard/Leaderboard.tsx";
import Shop from "./components/shop/Shop.tsx";
import { Heading } from "./components/common/Heading.js";
import Cart from "./components/cart/Cart.tsx";
import { OrderList } from "./components/orders/OrderList.tsx";



function App(props: { page: State }) {

    const padding = 40

    const [currentPage, setCurrentPage] = useState<State>("HOME");

    const [phoneNumber, setPhoneNumber] = useState<string>("")

    const renderContent = () => {
        switch (currentPage) {
            case "HOME":
                return (
                    <Profile></Profile>
                );
            case "LEADERBOARDS":
                return (
                    <Leaderboards></Leaderboards>
                )
            case "SHOP":
                return <Shop></Shop>
            case "ORDERS":
                return <Cart></Cart>
            // return <OrderList></OrderList>
            default:
                return (
                    <Profile ></Profile>
                )
        }
    };

    const pageContainer: React.CSSProperties = {
        backgroundColor: 'black',
        height: `calc(100vh - ${padding}px)`,
        padding: `${padding}px 40px`,
        overflowY: 'auto',
    }

    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    if (vw > 500) return (
        <div style={pageContainer}>
            <Heading
                name={'Этот сайт работает только на мобильных устройствах'}
            />
        </div>
    )

    return (
        <div style={pageContainer}>
            {renderContent()}
            <div style={{ height: `${NAVBAR_HEIGHT + 20}px` }}></div>
            <NavBar state={currentPage} />
        </div>
    );
}

export default App;
