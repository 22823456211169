import React from "react";
import useDriverProfile from "../../hooks/useDriverProfile.tsx";
import { formatBalance } from "../../utils/formatPrice.ts";
import { DriverProfile } from "../../types/driverProfile.ts";


const containerStyle: React.CSSProperties = {
    backgroundColor: 'black',
    color: 'white',
    paddingTop: '30px',
}

const staticTextStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '500',
    fontSize: '15px',
    color: '#B1B1B1',
    marginBottom: '8px'
}

const balanceStyle: React.CSSProperties = {
    fontFamily: 'Rubik, sans-serif',
    fontWeight: '400',
    fontSize: '48px',
    marginBottom: '12px'
}

const feeBadge: React.CSSProperties = {
    fontFamily: 'Rubik, sans-serif',
    backgroundColor: '#353535',
    display: 'inline-block',
    padding: '10px 15px',
    borderRadius: '100px'

}

function BalanceContainer(props: { driver: DriverProfile }) {


    if (!props.driver.accounts[0].balance) {
        return
    }

    return (
        <div style={containerStyle}>
            <div style={staticTextStyle}>БАЛАНС</div>
            <div style={balanceStyle}>{formatBalance(props.driver.accounts[0].balance)}</div>
            <div style={feeBadge}>0 бонусов</div>
        </div>
    )
}

export default BalanceContainer