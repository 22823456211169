import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import useProducts from "../../../hooks/useProducts.tsx";
import { Item } from "./Item.tsx";

export function ItemList({ activeFilter }) {

    const { products, isLoading } = useProducts(activeFilter);
    const [loadedImagesCount, setLoadedImagesCount] = useState(0);

    const totalProducts = products?.length || 0;
    const areImagesLoaded = totalProducts > 0 && loadedImagesCount >= totalProducts;

    const handleImageLoad = () => {
        setLoadedImagesCount((prev) => prev + 1);
        console.log(loadedImagesCount)
        console.log(totalProducts)

    };

    useEffect(() => {
        // Сброс счётчика при смене фильтра
        setLoadedImagesCount(0);
        console.log(loadedImagesCount)
    }, [activeFilter]);

    const container: React.CSSProperties = {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        justifyContent: "space-between",
    };

    const itemStyle: React.CSSProperties = {
        width: "calc(50% - 10px)",
        boxSizing: "border-box",
    };

    const skeletonStyle: React.CSSProperties = {
        width: "calc(50% - 10px)",
        height: "140px", // Обязательно задайте фиксированную высоту
        display: 'flex',  // Возможно, добавление flex поможет выровнять
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "70px"

    };

    return (
        <div style={container}>
            {/* Скелетоны отображаются, если товары или изображения ещё загружаются */}
            {(isLoading || !areImagesLoaded) &&
                Array(4)
                    .fill(null)
                    .map((_, index) => (
                        <div style={skeletonStyle} key={`skeleton-${index}`}>
                            <Skeleton sx={{ bgcolor: 'grey.900', width: "100%", height: "167%" }}
                            />
                        </div>
                    ))}

            {/* Товары отображаются, даже если ещё не все изображения загружены */}
            {products?.map((item, index) => (
                <div
                    style={{
                        ...itemStyle,
                        visibility: areImagesLoaded ? "visible" : "hidden", // Скрываем товары до загрузки
                    }}
                    key={index}
                >
                    <Item item={item} onImageLoad={handleImageLoad} />
                </div>
            ))}
        </div>
    );
}
